import { QuestionCategory } from 'shared/constants/header'

import { Screen, ScreenId } from './types'

export const screensConfig: { [key in ScreenId]: Screen } = {
  // start screens
  start_age: {
    id: 'start_age',
    url: '/start_age',
    config: {
      step: false,
    },
  },

  // onboarding screens
  /* PLOP_ONBOARDING_SCREENS */
  ob_result: {
    id: 'ob_result',
    url: '/ob_result',
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_motivation: {
    id: 'ob_motivation',
    url: '/ob_motivation',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_life_events: {
    id: 'ob_life_events',
    url: '/ob_life_events',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  bad_habits: {
    id: 'bad_habits',
    url: '/bad_habits',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_fortunately: {
    id: 'ob_fortunately',
    url: '/ob_fortunately',
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_childhood_trauma: {
    id: 'ob_childhood_trauma',
    url: '/ob_childhood_trauma',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_trust_feedback: {
    id: 'ob_trust_feedback',
    url: '/ob_trust_feedback',
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_sleep: {
    id: 'ob_sleep',
    url: '/ob_sleep',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_creating_3: {
    id: 'ob_creating_3',
    url: '/ob_creating_3',
    config: {
      step: false,
    },
  },

  ob_health_conditions: {
    id: 'ob_health_conditions',
    url: '/ob_health_conditions',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_medications: {
    id: 'ob_medications',
    url: '/ob_medications',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_describes_best: {
    id: 'ob_describes_best',
    url: '/ob_describes_best',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_water: {
    id: 'ob_water',
    url: '/ob_water',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_walking: {
    id: 'ob_walking',
    url: '/ob_walking',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_stairs: {
    id: 'ob_stairs',
    url: '/ob_stairs',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_job_active: {
    id: 'ob_job_active',
    url: '/ob_job_active',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_job_load: {
    id: 'ob_job_load',
    url: '/ob_job_load',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_meals_feel: {
    id: 'ob_meals_feel',
    url: '/ob_meals_feel',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_welcome_to_md: {
    id: 'ob_welcome_to_md',
    url: '/ob_welcome_to_md',
    config: {
      step: false,
    },
  },

  ob_creating_b: {
    id: 'ob_creating_b',
    url: '/ob_creating_b',
    config: {
      step: false,
    },
  },

  ob_time_dinner: {
    id: 'ob_time_dinner',
    url: '/ob_time_dinner',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_time_lunch: {
    id: 'ob_time_lunch',
    url: '/ob_time_lunch',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_time_breakfast: {
    id: 'ob_time_breakfast',
    url: '/ob_time_breakfast',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_happy_weight: {
    id: 'ob_happy_weight',
    url: '/ob_happy_weight',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_target_body_type_a: {
    id: 'ob_target_body_type_a',
    url: '/ob_target_body_type_a',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_goals_mix_b: {
    id: 'ob_goals_mix_b',
    url: '/ob_goals_mix_b',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_gender: {
    id: 'ob_gender',
    url: '/ob_gender',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_social_proof: {
    id: 'ob_social_proof',
    url: '/ob_social_proof',
    config: {
      step: true,
    },
  },

  ob_occasion_result: {
    id: 'ob_occasion_result',
    url: '/ob_occasion_result',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_current_body_type_a: {
    id: 'ob_current_body_type_a',
    url: '/ob_current_body_type_a',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  feedback_summary_fitlevel: {
    id: 'feedback_summary_fitlevel',
    url: '/feedback_summary_fitlevel',
    config: {
      step: false,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_tags: {
    id: 'ob_tags',
    url: '/ob_tags',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_target_zones_b: {
    id: 'ob_target_zones_b',
    url: '/ob_target_zones_b',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_fitness_level_a: {
    id: 'ob_fitness_level_a',
    url: '/ob_fitness_level_a',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_weight_gain_feedback: {
    id: 'ob_weight_gain_feedback',
    url: '/ob_weight_gain_feedback',
    config: {},
  },

  ob_mindea_different: {
    id: 'ob_mindea_different',
    url: '/ob_mindea_different',
    config: {},
  },

  ob_lifestyle: {
    id: 'ob_lifestyle',
    url: '/ob_lifestyle',
    config: {
      step: true,
      questionCategory: QuestionCategory.GOAL,
    },
  },

  ob_weight_gain: {
    id: 'ob_weight_gain',
    url: '/ob_weight_gain',
    config: {
      step: true,
      questionCategory: QuestionCategory.BIO,
    },
  },

  ob_health_issues: {
    id: 'ob_health_issues',
    url: '/ob_health_issues',
    config: {
      step: true,
      questionCategory: QuestionCategory.BIO,
    },
  },

  ob_diets: {
    id: 'ob_diets',
    url: '/ob_diets',
    config: {
      step: true,
      questionCategory: QuestionCategory.BIO,
    },
  },

  ob_funnel_done_thank_you_android: {
    id: 'ob_funnel_done_thank_you_android',
    url: '/ob_funnel_done_thank_you_android',
    config: {},
  },

  ob_feedback_skip_funnel: {
    id: 'ob_feedback_skip_funnel',
    url: '/ob_feedback_skip_funnel',
    config: {
      step: false,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_create_behavior_profile: {
    id: 'ob_create_behavior_profile',
    url: '/ob_create_behavior_profile',
    config: {
      step: false,
      replace: true,
    },
  },

  ob_alt_email_input: {
    id: 'ob_alt_email_input',
    url: '/ob_alt_email_input',
    config: {
      step: false,
    },
  },

  start_food_relationship: {
    id: 'start_food_relationship',
    url: '/start_food_relationship',
    config: {},
  },

  ob_nhe_quiz_external_feedback: {
    id: 'ob_nhe_quiz_external_feedback',
    url: '/ob_nhe_quiz_external_feedback',
    config: {
      step: false,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_emotional_feedback: {
    id: 'ob_nhe_quiz_emotional_feedback',
    url: '/ob_nhe_quiz_emotional_feedback',
    config: {
      step: false,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_restrained_feedback: {
    id: 'ob_nhe_quiz_restrained_feedback',
    url: '/ob_nhe_quiz_restrained_feedback',
    config: {
      step: false,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_email_consent: {
    id: 'ob_email_consent',
    url: '/ob_email_consent',
    config: {
      step: false,
    },
  },

  ob_weight_graph_nhe: {
    id: 'ob_weight_graph_nhe',
    url: '/ob_weight_graph_nhe',
    config: {
      step: false,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nutrition_cravings: {
    id: 'ob_nutrition_cravings',
    url: '/ob_nutrition_cravings',
    config: {
      step: true,
      questionCategory: QuestionCategory.NUTRITION,
    },
  },

  ob_feedback_nutrition_restrictive: {
    id: 'ob_feedback_nutrition_restrictive',
    url: '/ob_feedback_nutrition_restrictive',
    config: {
      step: false,
      questionCategory: QuestionCategory.NUTRITION,
    },
  },

  ob_nutrition_hungry_time: {
    id: 'ob_nutrition_hungry_time',
    url: '/ob_nutrition_hungry_time',
    config: {
      step: true,
      questionCategory: QuestionCategory.NUTRITION,
    },
  },

  ob_nutrition_stress_handle: {
    id: 'ob_nutrition_stress_handle',
    url: '/ob_nutrition_stress_handle',
    config: {
      step: true,
      questionCategory: QuestionCategory.NUTRITION,
    },
  },

  ob_nutrition_meal_type: {
    id: 'ob_nutrition_meal_type',
    url: '/ob_nutrition_meal_type',
    config: {
      step: true,
      questionCategory: QuestionCategory.NUTRITION,
    },
  },

  ob_nutrition_eat_habits: {
    id: 'ob_nutrition_eat_habits',
    url: '/ob_nutrition_eat_habits',
    config: {
      step: true,
      questionCategory: QuestionCategory.NUTRITION,
    },
  },

  ob_nutrition_st_fit_wl: {
    id: 'ob_nutrition_st_fit_wl',
    url: '/ob_nutrition_st_fit_wl',
    config: {
      step: true,
      questionCategory: QuestionCategory.NUTRITION,
    },
  },

  ob_behavior_push: {
    id: 'ob_behavior_push',
    url: '/ob_behavior_push',
    config: {
      step: true,
      questionCategory: QuestionCategory.BEHAVIOR_AND_EMOTIONS,
    },
  },

  ob_behavior_guilt: {
    id: 'ob_behavior_guilt',
    url: '/ob_behavior_guilt',
    config: {
      step: true,
      questionCategory: QuestionCategory.BEHAVIOR_AND_EMOTIONS,
    },
  },

  ob_behavior_social: {
    id: 'ob_behavior_social',
    url: '/ob_behavior_social',
    config: {
      step: true,
      questionCategory: QuestionCategory.BEHAVIOR_AND_EMOTIONS,
    },
  },

  ob_behavior_trigger: {
    id: 'ob_behavior_trigger',
    url: '/ob_behavior_trigger',
    config: {
      step: true,
      questionCategory: QuestionCategory.BEHAVIOR_AND_EMOTIONS,
    },
  },

  ob_behavior_st_plate: {
    id: 'ob_behavior_st_plate',
    url: '/ob_behavior_st_plate',
    config: {
      step: true,
      questionCategory: QuestionCategory.BEHAVIOR_AND_EMOTIONS,
    },
  },

  ob_behavior_st_socialize: {
    id: 'ob_behavior_st_socialize',
    url: '/ob_behavior_st_socialize',
    config: {
      step: true,
      questionCategory: QuestionCategory.BEHAVIOR_AND_EMOTIONS,
    },
  },

  ob_bio_sex: {
    id: 'ob_bio_sex',
    url: '/ob_bio_sex',
    config: {
      step: true,
      questionCategory: QuestionCategory.BIO,
    },
  },

  ob_weight_loss_pace: {
    id: 'ob_weight_loss_pace',
    url: '/ob_weight_loss_pace',
    config: {
      step: true,
      questionCategory: QuestionCategory.GOAL,
    },
  },

  ob_weight_loss_focus: {
    id: 'ob_weight_loss_focus',
    url: '/ob_weight_loss_focus',
    config: {
      step: true,
      questionCategory: QuestionCategory.GOAL,
    },
  },

  ob_feedback_program_ready: {
    id: 'ob_feedback_program_ready',
    url: '/ob_feedback_program_ready',
    config: {
      step: false,
    },
  },

  ob_feedback_nutrition_emotions: {
    id: 'ob_feedback_nutrition_emotions',
    url: '/ob_feedback_nutrition_emotions',
    config: {
      step: false,
      questionCategory: QuestionCategory.NUTRITION,
    },
  },

  ob_nutrition_start: {
    id: 'ob_nutrition_start',
    url: '/ob_nutrition_start',
    config: {
      step: false,
      questionCategory: QuestionCategory.NUTRITION,
    },
  },

  ob_nhe_quiz_start: {
    id: 'ob_nhe_quiz_start',
    url: '/ob_nhe_quiz_start',
    config: {
      step: false,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_feedback_behavior_end: {
    id: 'ob_feedback_behavior_end',
    url: '/ob_feedback_behavior_end',
    config: {
      step: false,
      questionCategory: QuestionCategory.BEHAVIOR_AND_EMOTIONS,
    },
  },

  ob_feedback_bio: {
    id: 'ob_feedback_bio',
    url: '/ob_feedback_bio',
    config: {
      step: false,
      questionCategory: QuestionCategory.BEHAVIOR_AND_EMOTIONS,
    },
  },

  ob_feedback_goal_focus: {
    id: 'ob_feedback_goal_focus',
    url: '/ob_feedback_goal_focus',
    config: {
      step: false,
      questionCategory: QuestionCategory.GOAL,
    },
  },

  ob_feedback_goal_set: {
    id: 'ob_feedback_goal_set',
    url: '/ob_feedback_goal_set',
    config: {
      step: false,
      questionCategory: QuestionCategory.GOAL,
    },
  },

  ob_nhe_quiz_external_start: {
    id: 'ob_nhe_quiz_external_start',
    url: '/ob_nhe_quiz_external_start',
    config: {
      step: false,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_restrained_start: {
    id: 'ob_nhe_quiz_restrained_start',
    url: '/ob_nhe_quiz_restrained_start',
    config: {
      step: false,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_external_10: {
    id: 'ob_nhe_quiz_external_10',
    url: '/ob_nhe_quiz_external_10',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_external_9: {
    id: 'ob_nhe_quiz_external_9',
    url: '/ob_nhe_quiz_external_9',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_external_8: {
    id: 'ob_nhe_quiz_external_8',
    url: '/ob_nhe_quiz_external_8',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_external_7: {
    id: 'ob_nhe_quiz_external_7',
    url: '/ob_nhe_quiz_external_7',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_external_6: {
    id: 'ob_nhe_quiz_external_6',
    url: '/ob_nhe_quiz_external_6',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_external_5: {
    id: 'ob_nhe_quiz_external_5',
    url: '/ob_nhe_quiz_external_5',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_external_4: {
    id: 'ob_nhe_quiz_external_4',
    url: '/ob_nhe_quiz_external_4',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_external_3: {
    id: 'ob_nhe_quiz_external_3',
    url: '/ob_nhe_quiz_external_3',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_external_2: {
    id: 'ob_nhe_quiz_external_2',
    url: '/ob_nhe_quiz_external_2',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_external_1: {
    id: 'ob_nhe_quiz_external_1',
    url: '/ob_nhe_quiz_external_1',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_emotional_13: {
    id: 'ob_nhe_quiz_emotional_13',
    url: '/ob_nhe_quiz_emotional_13',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_emotional_12: {
    id: 'ob_nhe_quiz_emotional_12',
    url: '/ob_nhe_quiz_emotional_12',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_emotional_11: {
    id: 'ob_nhe_quiz_emotional_11',
    url: '/ob_nhe_quiz_emotional_11',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_emotional_10: {
    id: 'ob_nhe_quiz_emotional_10',
    url: '/ob_nhe_quiz_emotional_10',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_emotional_9: {
    id: 'ob_nhe_quiz_emotional_9',
    url: '/ob_nhe_quiz_emotional_9',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_emotional_8: {
    id: 'ob_nhe_quiz_emotional_8',
    url: '/ob_nhe_quiz_emotional_8',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_emotional_7: {
    id: 'ob_nhe_quiz_emotional_7',
    url: '/ob_nhe_quiz_emotional_7',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_emotional_6: {
    id: 'ob_nhe_quiz_emotional_6',
    url: '/ob_nhe_quiz_emotional_6',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_emotional_5: {
    id: 'ob_nhe_quiz_emotional_5',
    url: '/ob_nhe_quiz_emotional_5',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_emotional_4: {
    id: 'ob_nhe_quiz_emotional_4',
    url: '/ob_nhe_quiz_emotional_4',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_emotional_3: {
    id: 'ob_nhe_quiz_emotional_3',
    url: '/ob_nhe_quiz_emotional_3',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_emotional_2: {
    id: 'ob_nhe_quiz_emotional_2',
    url: '/ob_nhe_quiz_emotional_2',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_emotional_1: {
    id: 'ob_nhe_quiz_emotional_1',
    url: '/ob_nhe_quiz_emotional_1',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_restrained_3: {
    id: 'ob_nhe_quiz_restrained_3',
    url: '/ob_nhe_quiz_restrained_3',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_restrained_10: {
    id: 'ob_nhe_quiz_restrained_10',
    url: '/ob_nhe_quiz_restrained_10',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_restrained_9: {
    id: 'ob_nhe_quiz_restrained_9',
    url: '/ob_nhe_quiz_restrained_9',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_restrained_8: {
    id: 'ob_nhe_quiz_restrained_8',
    url: '/ob_nhe_quiz_restrained_8',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_restrained_7: {
    id: 'ob_nhe_quiz_restrained_7',
    url: '/ob_nhe_quiz_restrained_7',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_restrained_6: {
    id: 'ob_nhe_quiz_restrained_6',
    url: '/ob_nhe_quiz_restrained_6',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_restrained_5: {
    id: 'ob_nhe_quiz_restrained_5',
    url: '/ob_nhe_quiz_restrained_5',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_restrained_4: {
    id: 'ob_nhe_quiz_restrained_4',
    url: '/ob_nhe_quiz_restrained_4',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_restrained_2: {
    id: 'ob_nhe_quiz_restrained_2',
    url: '/ob_nhe_quiz_restrained_2',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_restrained_1: {
    id: 'ob_nhe_quiz_restrained_1',
    url: '/ob_nhe_quiz_restrained_1',
    config: {
      step: true,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },
  ob_nhe_quiz_result: {
    id: 'ob_nhe_quiz_result',
    url: '/ob_nhe_quiz_result',
    config: {
      step: false,
    },
  },
  ob_create_program_final: {
    id: 'ob_create_program_final',
    url: '/ob_create_program_final',
    config: {
      step: false,
      replace: true,
    },
  },
  ob_feedback_create_program_screens: {
    id: 'ob_feedback_create_program_screens',
    url: '/ob_feedback_create_program_screens',
    config: {
      step: false,
    },
  },
  ob_weight_graph: {
    id: 'ob_weight_graph',
    url: '/ob_weight_graph',
    config: {
      step: false,
    },
  },
  ob_email_input: {
    id: 'ob_email_input',
    url: '/ob_email_input',
    config: {
      step: false,
    },
  },

  ob_bio_height: {
    id: 'ob_bio_height',
    url: '/ob_bio_height',
    config: {
      step: true,
      questionCategory: QuestionCategory.GOAL,
    },
  },
  ob_bio_age: {
    id: 'ob_bio_age',
    url: '/ob_bio_age',
    config: {
      step: true,
      questionCategory: QuestionCategory.BIO,
    },
  },
  ob_weight: {
    id: 'ob_weight',
    url: '/ob_weight',
    config: {
      step: true,
      questionCategory: QuestionCategory.GOAL,
    },
  },
  ob_target_weight: {
    id: 'ob_target_weight',
    url: '/ob_target_weight',
    config: {
      step: true,
      questionCategory: QuestionCategory.GOAL,
    },
  },
  ob_occasion: {
    id: 'ob_occasion',
    url: '/ob_occasion',
    config: {
      step: true,
      questionCategory: QuestionCategory.GOAL,
    },
  },
  ob_feedback_nutrition_eat_all: {
    id: 'ob_feedback_nutrition_eat_all',
    url: '/ob_feedback_nutrition_eat_all',
    config: {
      step: false,
    },
  },
  ob_nhe_quiz_emotional_start: {
    id: 'ob_nhe_quiz_emotional_start',
    url: '/ob_nhe_quiz_emotional_start',
    config: {
      step: false,
    },
  },
  ob_bio_feedback: {
    id: 'ob_bio_feedback',
    url: '/ob_bio_feedback',
    config: {
      step: false,
    },
  },
  ob_feedback_health_goal: {
    id: 'ob_feedback_health_goal',
    url: '/ob_feedback_health_goal',
    config: {
      step: false,
    },
  },

  ob_health_goal_select: {
    id: 'ob_health_goal_select',
    url: '/ob_health_goal_select',
    config: {
      step: true,
      questionCategory: QuestionCategory.GOAL,
    },
  },

  ob_weight_loss_motivation: {
    id: 'ob_weight_loss_motivation',
    url: '/ob_weight_loss_motivation',
    config: {
      step: true,
      questionCategory: QuestionCategory.GOAL,
    },
  },

  // payment screens
  payment_screen: {
    id: 'payment_screen',
    url: '/payment_screen',
    config: {
      replace: true,
      checkoutScreenId: 'checkout_foxtrot',
      motivationScreenId: 'payment_exit_intent',
    },
  },

  payment_screen_2: {
    id: 'payment_screen_2',
    url: '/payment_screen_2',
    config: {
      replace: true,
      checkoutScreenId: 'checkout_foxtrot',
      motivationScreenId: 'payment_exit_intent',
    },
  },

  payment_base: {
    id: 'payment_base',
    url: '/payment_base',
    config: {
      replace: true,
      checkoutScreenId: 'checkout_golf',
      motivationScreenId: 'payment_exit_intent',
    },
  },

  payment_discount: {
    id: 'payment_discount',
    url: '/payment_discount',
    config: {
      replace: true,
      checkoutScreenId: 'checkout_foxtrot',
      motivationScreenId: 'payment_exit_intent',
    },
  },

  offer_a5: {
    id: 'offer_a5',
    url: '/offer_a5',
    config: {
      replace: true,
      checkoutScreenId: 'checkout_golf',
      motivationScreenId: 'payment_exit_intent',
    },
  },

  // post checkout screens

  ob_payment_success_register: {
    id: 'ob_payment_success_register',
    url: '/ob_payment_success_register',
    config: {
      step: false,
      replace: true,
    },
  },

  ob_funnel_done_thank_you: {
    id: 'ob_funnel_done_thank_you',
    url: '/ob_funnel_done_thank_you',
    config: {
      step: false,
    },
  },
}
