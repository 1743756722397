import { HeightUnit, ReducerActionType, UnitsSystem, WeightUnit } from './constants'
import { getUnitsSystemByWeightUnit } from './helper'
import { Action, State } from './types'

export const INITIAL_STATE: State = {
  country: '',
  unitSystem: UnitsSystem.METRIC,
  weightUnits: Object.values(WeightUnit) as Array<WeightUnit>,
  heightUnits: Object.values(HeightUnit) as Array<HeightUnit>,
  ageGroup: '',
  gender: '',
  currentWeight: {
    unit: '',
    value: '',
  },
  targetWeight: {
    unit: '',
    value: '',
  },
  height: {
    unit: '',
    value: '',
  },
  /* PLOP_INITIAL_STATE */
  motivation: undefined,
  lifeEvents: [],
  badHabits: [],
  childhoodTrauma: [],
  sleep: undefined,
  healthConditions: [],
  medications: [],
  describesBest: undefined,
  water: undefined,
  walking: undefined,
  stairs: undefined,
  jobActive: undefined,
  jobLoad: undefined,
  mealsFeel: undefined,
  timeDinner: undefined,
  timeLunch: undefined,
  timeBreakfast: undefined,
  lastHappyWeight: undefined,
  targetBodyType: undefined,
  healthGoals: [],
  currentBodyType: undefined,
  focus: [],
  targetZones: [],
  fitnessLevel: undefined,
  lifeBusyness: [],
  weightGainReasons: [],
  healthIssues: [],
  triedDiets: undefined,
  emailConsent: undefined,
  nutritionFoodCravings: undefined,
  nutritionHungryTimeOfDay: undefined,
  nutritionStressEating: undefined,
  nutritionPreferredMeal: undefined,
  nutritionEatingHabits: undefined,
  nutritionHowToFitInMyLife: undefined,
  behaviorPush: undefined,
  behaviorGuilt: undefined,
  behaviorSocial: undefined,
  behaviorTrigger: undefined,
  behaviorStPlate: undefined,
  behaviorStSocialize: undefined,
  weightLossPace: undefined,
  weightLossFocus: undefined,
  debqExternalSnackingWhileCooking: undefined,
  debqExternalEatMoreWhenOthersEating: undefined,
  debqExternalHardToResistTastyFood: undefined,
  debqExternalEatWhenSeeEatingPeople: undefined,
  debqExternalBuyFoodWhenPassingCafe: undefined,
  debqExternalBuyFoodWhenPassingBakery: undefined,
  debqExternalEatDeliciousFoodInstantly: undefined,
  debqExternalEatIfGoodLookingFood: undefined,
  debqExternalEatMoreWhenGoodLookingFood: undefined,
  debqExternalEatTastyFood: undefined,
  debqEmotionalEatWhenBored: undefined,
  debqEmotionalEatWhenEmotionalUpset: undefined,
  debqEmotionalEatWhenDisappointed: undefined,
  debqEmotionalEatWhenScared: undefined,
  debqEmotionalEatWhenFailed: undefined,
  debqEmotionalEatWhenWorried: undefined,
  debqEmotionalEatDuringUnpleasantAnticipation: undefined,
  debqEmotionalEatWhenUpset: undefined,
  debqEmotionalEatWhenLetDown: undefined,
  debqEmotionalEatWhenLonely: undefined,
  debqEmotionalEatWhenDepressed: undefined,
  debqEmotionalEatWhenChilling: undefined,
  debqEmotionalEatWhenIrritated: undefined,
  debqRestrainedRefuseFood: undefined,
  debqRestrainedConsiderWeightWhenChooseFood: undefined,
  debqRestrainedNoEveningEating: undefined,
  debqRestrainedNoEatBetweenMeals: undefined,
  debqRestrainedEatLess: undefined,
  debqRestrainedEatLessAfterOvereating: undefined,
  debqRestrainedFoodForWl: undefined,
  debqRestrainedEatingMonitoring: undefined,
  debqRestrainedEatLessDuringMeals: undefined,
  debqRestrainedEatLessWhenGainWeight: undefined,
  age: undefined,
  occasion: undefined,
  occasionDate: undefined,
  weightLossMotivations: [],
  healthGoalReason: undefined,
}

// eslint-disable-next-line complexity
export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ReducerActionType.SET_AGE_GROUP: {
      return { ...state, ageGroup: action.payload }
    }

    case ReducerActionType.SET_GENDER: {
      return { ...state, gender: action.payload }
    }

    case ReducerActionType.SET_CURRENT_WEIGHT: {
      const { unit } = action.payload
      const unitSystem = getUnitsSystemByWeightUnit(unit)

      return {
        ...state,
        unitSystem,
        currentWeight: { ...action.payload },
      }
    }

    case ReducerActionType.SET_TARGET_WEIGHT: {
      const { unit } = action.payload
      const unitSystem = getUnitsSystemByWeightUnit(unit)

      return {
        ...state,
        unitSystem,
        targetWeight: { ...action.payload },
      }
    }

    /* PLOP_REDUCER_ACTION */
    case ReducerActionType.SET_MOTIVATION: {
      return { ...state, motivation: action.payload }
    }

    case ReducerActionType.SET_LIFE_EVENTS: {
      return { ...state, lifeEvents: action.payload }
    }

    case ReducerActionType.SET_BAD_HABITS: {
      return { ...state, badHabits: action.payload }
    }

    case ReducerActionType.SET_CHILDHOOD_TRAUMA: {
      return { ...state, childhoodTrauma: action.payload }
    }

    case ReducerActionType.SET_SLEEP: {
      return { ...state, sleep: action.payload }
    }

    case ReducerActionType.SET_HEALTH_CONDITIONS: {
      return { ...state, healthConditions: action.payload }
    }

    case ReducerActionType.SET_MEDICATIONS: {
      return { ...state, medications: action.payload }
    }

    case ReducerActionType.SET_DESCRIBES_BEST: {
      return { ...state, describesBest: action.payload }
    }

    case ReducerActionType.SET_WATER: {
      return { ...state, water: action.payload }
    }

    case ReducerActionType.SET_WALKING: {
      return { ...state, walking: action.payload }
    }

    case ReducerActionType.SET_STAIRS: {
      return { ...state, stairs: action.payload }
    }

    case ReducerActionType.SET_JOB_ACTIVE: {
      return { ...state, jobActive: action.payload }
    }

    case ReducerActionType.SET_JOB_LOAD: {
      return { ...state, jobLoad: action.payload }
    }

    case ReducerActionType.SET_MEALS_FEEL: {
      return { ...state, mealsFeel: action.payload }
    }

    case ReducerActionType.SET_TIME_DINNER: {
      return { ...state, timeDinner: action.payload }
    }

    case ReducerActionType.SET_TIME_LUNCH: {
      return { ...state, timeLunch: action.payload }
    }

    case ReducerActionType.SET_TIME_BREAKFAST: {
      return { ...state, timeBreakfast: action.payload }
    }

    case ReducerActionType.SET_LAST_HAPPY_WEIGHT: {
      return { ...state, lastHappyWeight: action.payload }
    }

    case ReducerActionType.SET_TARGET_BODY_TYPE: {
      return { ...state, targetBodyType: action.payload }
    }

    case ReducerActionType.SET_HEALTH_GOALS: {
      return { ...state, healthGoals: action.payload }
    }

    case ReducerActionType.SET_CURRENT_BODY_TYPE: {
      return { ...state, currentBodyType: action.payload }
    }

    case ReducerActionType.SET_FOCUS: {
      return { ...state, focus: action.payload }
    }

    case ReducerActionType.SET_TARGET_ZONES: {
      return { ...state, targetZones: action.payload }
    }

    case ReducerActionType.SET_FITNESS_LEVEL: {
      return { ...state, fitnessLevel: action.payload }
    }

    case ReducerActionType.SET_LIFE_BUSYNESS: {
      return { ...state, lifeBusyness: action.payload }
    }

    case ReducerActionType.SET_WEIGHT_GAIN_REASONS: {
      return { ...state, weightGainReasons: action.payload }
    }

    case ReducerActionType.SET_HEALTH_ISSUES: {
      return { ...state, healthIssues: action.payload }
    }

    case ReducerActionType.SET_TRIED_DIETS: {
      return { ...state, triedDiets: action.payload }
    }

    case ReducerActionType.SET_EMAIL_CONSENT: {
      return { ...state, emailConsent: action.payload }
    }

    case ReducerActionType.SET_NUTRITION_FOOD_CRAVINGS: {
      return { ...state, nutritionFoodCravings: action.payload }
    }

    case ReducerActionType.SET_NUTRITION_HUNGRY_TIME_OF_DAY: {
      return { ...state, nutritionHungryTimeOfDay: action.payload }
    }

    case ReducerActionType.SET_NUTRITION_STRESS_EATING: {
      return { ...state, nutritionStressEating: action.payload }
    }

    case ReducerActionType.SET_NUTRITION_PREFERRED_MEAL: {
      return { ...state, nutritionPreferredMeal: action.payload }
    }

    case ReducerActionType.SET_NUTRITION_EATING_HABITS: {
      return { ...state, nutritionEatingHabits: action.payload }
    }

    case ReducerActionType.SET_NUTRITION_HOW_TO_FIT_IN_MY_LIFE: {
      return { ...state, nutritionHowToFitInMyLife: action.payload }
    }

    case ReducerActionType.SET_BEHAVIOR_PUSH: {
      return { ...state, behaviorPush: action.payload }
    }

    case ReducerActionType.SET_BEHAVIOR_GUILT: {
      return { ...state, behaviorGuilt: action.payload }
    }

    case ReducerActionType.SET_BEHAVIOR_SOCIAL: {
      return { ...state, behaviorSocial: action.payload }
    }

    case ReducerActionType.SET_BEHAVIOR_TRIGGER: {
      return { ...state, behaviorTrigger: action.payload }
    }

    case ReducerActionType.SET_BEHAVIOR_ST_PLATE: {
      return { ...state, behaviorStPlate: action.payload }
    }

    case ReducerActionType.SET_BEHAVIOR_ST_SOCIALIZE: {
      return { ...state, behaviorStSocialize: action.payload }
    }

    case ReducerActionType.SET_WEIGHT_LOSS_PACE: {
      return { ...state, weightLossPace: action.payload }
    }

    case ReducerActionType.SET_WEIGHT_LOSS_FOCUS: {
      return { ...state, weightLossFocus: action.payload }
    }

    case ReducerActionType.SET_DEBQ_EXTERNAL_SNACKING_WHILE_COOKING: {
      return { ...state, debqExternalSnackingWhileCooking: action.payload }
    }

    case ReducerActionType.SET_DEBQ_EXTERNAL_EAT_MORE_WHEN_OTHERS_EATING: {
      return { ...state, debqExternalEatMoreWhenOthersEating: action.payload }
    }

    case ReducerActionType.SET_DEBQ_EXTERNAL_HARD_TO_RESIST_TASTY_FOOD: {
      return { ...state, debqExternalHardToResistTastyFood: action.payload }
    }

    case ReducerActionType.SET_DEBQ_EXTERNAL_EAT_WHEN_SEE_EATING_PEOPLE: {
      return { ...state, debqExternalEatWhenSeeEatingPeople: action.payload }
    }

    case ReducerActionType.SET_DEBQ_EXTERNAL_BUY_FOOD_WHEN_PASSING_CAFE: {
      return { ...state, debqExternalBuyFoodWhenPassingCafe: action.payload }
    }

    case ReducerActionType.SET_DEBQ_EXTERNAL_BUY_FOOD_WHEN_PASSING_BAKERY: {
      return { ...state, debqExternalBuyFoodWhenPassingBakery: action.payload }
    }

    case ReducerActionType.SET_DEBQ_EXTERNAL_EAT_DELICIOUS_FOOD_INSTANTLY: {
      return { ...state, debqExternalEatDeliciousFoodInstantly: action.payload }
    }

    case ReducerActionType.SET_DEBQ_EXTERNAL_EAT_IF_GOOD_LOOKING_FOOD: {
      return { ...state, debqExternalEatIfGoodLookingFood: action.payload }
    }

    case ReducerActionType.SET_DEBQ_EXTERNAL_EAT_MORE_WHEN_GOOD_LOOKING_FOOD: {
      return { ...state, debqExternalEatMoreWhenGoodLookingFood: action.payload }
    }

    case ReducerActionType.SET_DEBQ_EXTERNAL_EAT_TASTY_FOOD: {
      return { ...state, debqExternalEatTastyFood: action.payload }
    }

    case ReducerActionType.SET_DEBQ_EMOTIONAL_EAT_WHEN_BORED: {
      return { ...state, debqEmotionalEatWhenBored: action.payload }
    }

    case ReducerActionType.SET_DEBQ_EMOTIONAL_EAT_WHEN_EMOTIONAL_UPSET: {
      return { ...state, debqEmotionalEatWhenEmotionalUpset: action.payload }
    }

    case ReducerActionType.SET_DEBQ_EMOTIONAL_EAT_WHEN_DISAPPOINTED: {
      return { ...state, debqEmotionalEatWhenDisappointed: action.payload }
    }

    case ReducerActionType.SET_DEBQ_EMOTIONAL_EAT_WHEN_SCARED: {
      return { ...state, debqEmotionalEatWhenScared: action.payload }
    }

    case ReducerActionType.SET_DEBQ_EMOTIONAL_EAT_WHEN_FAILED: {
      return { ...state, debqEmotionalEatWhenFailed: action.payload }
    }

    case ReducerActionType.SET_DEBQ_EMOTIONAL_EAT_WHEN_WORRIED: {
      return { ...state, debqEmotionalEatWhenWorried: action.payload }
    }

    case ReducerActionType.SET_DEBQ_EMOTIONAL_EAT_DURING_UNPLEASANT_ANTICIPATION: {
      return { ...state, debqEmotionalEatDuringUnpleasantAnticipation: action.payload }
    }

    case ReducerActionType.SET_DEBQ_EMOTIONAL_EAT_WHEN_UPSET: {
      return { ...state, debqEmotionalEatWhenUpset: action.payload }
    }

    case ReducerActionType.SET_DEBQ_EMOTIONAL_EAT_WHEN_LET_DOWN: {
      return { ...state, debqEmotionalEatWhenLetDown: action.payload }
    }

    case ReducerActionType.SET_DEBQ_EMOTIONAL_EAT_WHEN_LONELY: {
      return { ...state, debqEmotionalEatWhenLonely: action.payload }
    }

    case ReducerActionType.SET_DEBQ_EMOTIONAL_EAT_WHEN_DEPRESSED: {
      return { ...state, debqEmotionalEatWhenDepressed: action.payload }
    }

    case ReducerActionType.SET_DEBQ_EMOTIONAL_EAT_WHEN_CHILLING: {
      return { ...state, debqEmotionalEatWhenChilling: action.payload }
    }

    case ReducerActionType.SET_DEBQ_EMOTIONAL_EAT_WHEN_IRRITATED: {
      return { ...state, debqEmotionalEatWhenIrritated: action.payload }
    }

    case ReducerActionType.SET_DEBQ_RESTRAINED_REFUSE_FOOD: {
      return { ...state, debqRestrainedRefuseFood: action.payload }
    }

    case ReducerActionType.SET_DEBQ_RESTRAINED_CONSIDER_WEIGHT_WHEN_CHOOSE_FOOD: {
      return { ...state, debqRestrainedConsiderWeightWhenChooseFood: action.payload }
    }

    case ReducerActionType.SET_DEBQ_RESTRAINED_NO_EVENING_EATING: {
      return { ...state, debqRestrainedNoEveningEating: action.payload }
    }

    case ReducerActionType.SET_DEBQ_RESTRAINED_NO_EAT_BETWEEN_MEALS: {
      return { ...state, debqRestrainedNoEatBetweenMeals: action.payload }
    }

    case ReducerActionType.SET_DEBQ_RESTRAINED_EAT_LESS: {
      return { ...state, debqRestrainedEatLess: action.payload }
    }

    case ReducerActionType.SET_DEBQ_RESTRAINED_EAT_LESS_AFTER_OVEREATING: {
      return { ...state, debqRestrainedEatLessAfterOvereating: action.payload }
    }

    case ReducerActionType.SET_DEBQ_RESTRAINED_FOOD_FOR_WL: {
      return { ...state, debqRestrainedFoodForWl: action.payload }
    }

    case ReducerActionType.SET_DEBQ_RESTRAINED_EATING_MONITORING: {
      return { ...state, debqRestrainedEatingMonitoring: action.payload }
    }

    case ReducerActionType.SET_DEBQ_RESTRAINED_EAT_LESS_DURING_MEALS: {
      return { ...state, debqRestrainedEatLessDuringMeals: action.payload }
    }

    case ReducerActionType.SET_DEBQ_RESTRAINED_EAT_LESS_WHEN_GAIN_WEIGHT: {
      return { ...state, debqRestrainedEatLessWhenGainWeight: action.payload }
    }

    case ReducerActionType.SET_HEIGHT: {
      return { ...state, height: action.payload }
    }

    case ReducerActionType.SET_AGE: {
      return { ...state, age: action.payload }
    }

    case ReducerActionType.SET_OCCASION: {
      return { ...state, occasion: action.payload }
    }

    case ReducerActionType.SET_OCCASION_DATE: {
      return { ...state, occasionDate: action.payload }
    }

    case ReducerActionType.SET_WEIGHT_LOSS_MOTIVATIONS: {
      return { ...state, weightLossMotivations: action.payload }
    }

    case ReducerActionType.SET_HEALTH_GOAL_REASON: {
      return { ...state, healthGoalReason: action.payload }
    }

    case ReducerActionType.SET_COUNTRY: {
      return { ...state, country: action.payload }
    }

    case ReducerActionType.SET_UNIT_SYSTEM: {
      return { ...state, unitSystem: action.payload }
    }

    default: {
      // @ts-expect-error
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}
